import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export function formatDate(value: string | Date, time: boolean = true): string | null {
    if (!value) {
        return null;
    }

    const date = dayjs(value);
    if (!date.isValid()) {
        return null;
    }

    let format = 'DD/MM/YYYY';
    if (time) {
        format += ' HH:mm';
    }

    return date.format(format);
}

export function formatTimeRange(startDate: string | Date, endDate: string | Date): string | null {
    if (!startDate || !endDate) {
        return null;
    }

    const start = dayjs(startDate);
    const end = dayjs(endDate);

    if (start.isSame(end, 'day')) {
        return start.format('HH:mm') + ' - ' + end.format('HH:mm');
    }

    return `${start.format('ddd HH:mm')} - ${end.format('ddd HH:mm')}`;
}

export function formatTime(value: string | Date): string | null {
    if (!value) {
        return null;
    }

    const date = dayjs(value);
    if (!date.isValid()) {
        return null;
    }

    return date.format('HH:mm');
}

export function friendlyFormatDate(value: string | Date, time: boolean = true): string | null {
    if (!value) {
        return null;
    }

    const date = dayjs(value);
    if (!date.isValid()) {
        return null;
    }

    if (date.isBefore(dayjs().subtract(1, 'day'))) {
        let format = 'DD/MM/YYYY';
        if (time) {
            format += ' HH:mm';
        }

        return date.format(format);
    }

    return date.fromNow();
}
